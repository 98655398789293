import { Camera } from '@capacitor/camera'
import { Filesystem } from '@capacitor/filesystem'
import { Microphone } from '@mozartec/capacitor-microphone';


const requestCameraPermission = async () => {
    const camera = await Camera.requestPermissions()
    return camera.camera === 'granted'
}

const requestMicrophonePermission = async () => {
    const micro = await Microphone.requestPermissions();
    return micro.microphone === 'granted'
}

const requestFileSystemPermission = async () => {
    const filesystem = await Filesystem.requestPermissions()
    return filesystem.publicStorage  === 'granted'
}

const requestPermissions = async (devices: any, platform: any) => {
    let permissionGranted = true
    if(devices.camera) {
        const permission = await requestCameraPermission();
        if(permission == false) permissionGranted = false
    }
    if(devices.microphone){
        const permission = await requestMicrophonePermission();
        if(permission == false) permissionGranted = false
    } 
    if(devices.screen && platform == 'android'){
        const permission = await requestFileSystemPermission();
        if(permission == false) permissionGranted = false
    } 

    return permissionGranted
}

export {
    requestPermissions,
    requestCameraPermission,
    requestMicrophonePermission,
    requestFileSystemPermission
}