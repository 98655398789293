
import { defineComponent, ref, computed, inject, onBeforeMount } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import Execution from '../../../commonvue/src/components/Execution/Execution.vue'
import {
    IonButton,
    IonPage,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
} from '@ionic/vue'
import {
    VideoRecorder,
    VideoRecorderCamera,
    VideoRecorderPreviewFrame,
} from '@teamhive/capacitor-video-recorder'
import {
    ScreenRecorder,
    RecorderConfig,
} from '@corban93/capacitor-screen-recorder'

import TestExecutionHeader from '@/components/TestExecutionHeader.vue'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'

import watStore from '@/store/watux.store'
import { AppVersion } from '@awesome-cordova-plugins/app-version'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser'
import {
  requestPermissions } from '@/utils/MobilePermissions'
import Swal from 'sweetalert2'
import Bugsnag from '@bugsnag/js'
import authStore from '@/store/auth.store'


export default defineComponent({
    name: 'Test',
    emits: ['rechargeTests'],
    props: {
        id: {
            type: String,
            default: null,
        },
    },
    components: {
        IonButton,
        IonPage,
        IonButtons,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        Execution,
        TestExecutionHeader,
    },
    data() {
        return {
            platform: '',
            appversion: '',
            isAppNative: false
        }
    },
    async mounted() {
        this.registerWatUXEventListeners()
        this.isAppNative = Capacitor.isNativePlatform()
        if (Capacitor.isNativePlatform()) {
            AppVersion.getVersionNumber().then((appversion) => {
                this.appversion = appversion
            })
        }
    },
    methods: {
        bugsnagInfo(step: any){
            const user = authStore.getUserData()
            Bugsnag.notify(step.detail + ' - ' + user.identifier, function (event: any) {
                event.severity = 'info'
                event.context = 'Wat Ux Logs - ' + user.identifier
                event.setUser(user.identifier, '', '')
            })
        },
        async requestPermissionsMobile(event: any){
            let permissionGranted =  await requestPermissions(event.detail.devices, this.platform)
            if(permissionGranted == true){
                this.bugsnagInfo({detail:'Permissions granted'})
                let event = new CustomEvent('watux-permissions-granted')
                window.dispatchEvent(event)
            }
            else{
                this.bugsnagInfo({detail:'Permissions denied'})
                this.permissionsWarning()
            }
        },
        // Setup
        registerWatUXEventListeners: function () {
            window.addEventListener('watux-request-permissions', (event) =>
                this.requestPermissionsMobile(<CustomEvent>event)
            )
            window.addEventListener('watux-recording', (event) =>
                this.handleWatUXRecordingEvent(<CustomEvent>event)
            )
            window.addEventListener('watux-checkpoint-completion', (event) =>
                this.handleWatUXCheckpointCompletionEvent(<CustomEvent>event)
            )
            window.addEventListener('watux-notify-record-insertion', (event) =>
                this.handleWatUXNotifyRecordInsertionEvent(<CustomEvent>event)
            )
            window.addEventListener('watux-data-validation', (event) =>
                this.handleWatUXDataValidationEvent(<CustomEvent>event)
            )
            window.addEventListener('watux-bugsnag-event',(event) =>
                this.bugsnagInfo(<CustomEvent>event)
            )
        },

        registerCapacitorAppListeners: function (active: boolean) {
            if (active) {
                App.removeAllListeners()

                App.addListener('appStateChange', ({ isActive }) => {
                    this.handleAppActiveState(isActive)
                })
            }
        },

        // Event methods
        handleWatUXRecordingEvent: async function (event: CustomEvent) {
            this.platform = event.detail.platform
            this.bugsnagInfo({detail:'Recording event: ' + event.detail.action})

            switch (event.detail.action) {
                case 'prepare':
                    this.blankVideoRecorderInitialize()
                    break
                case 'start':
                    this.startRecording(
                        event.detail.devices,
                        event.detail.initialized
                    )
                    break
                case 'stop':
                    this.stopRecording(event.detail.devices)
                    break
            }
        },

        handleWatUXCheckpointCompletionEvent: async function (
            event: CustomEvent
        ) {
            var data = event.detail

            watStore.notifyCompletion(data).catch((error: any) => {
                console.log(error)
            })
        },

        handleWatUXNotifyRecordInsertionEvent: async function (
            event: CustomEvent
        ) {
            var data = event.detail
            const vm = this
            watStore
                .notifyCompletion(data)
                .then((response) => {
                    var event = new CustomEvent(
                        'watux-notify-record-insertion-complete',
                        {
                            detail: {
                                platform: this.platform,
                                response: response,
                            },
                        }
                    )

                    window.dispatchEvent(event)
                })
                .catch((error: any) => {
                    this.errorRecordingWarning(vm)
                })
        },
        closeExecution(){
            const execution: any = this.$refs.execution
            execution.exitExecution()
        },
        handleWatUXDataValidationEvent: async function (event: CustomEvent) {
            var data = event.detail

            watStore
                .validateDataCompletion(data)
                .then((response) => {
                    var event = new CustomEvent(
                        'watux-data-validation-complete',
                        {
                            detail: {
                                platform: this.platform,
                                response: response,
                            },
                        }
                    )

                    window.dispatchEvent(event)
                })
                .catch((error: any) => {
                    console.log(error)
                })
        },

        handleAppActiveState: async function (active: boolean) {
            if (!active) {
                const promises = []

                promises.push(await this.stopVideoRecord())
                promises.push(await this.destroyVideoRecorder())
                promises.push(await this.stopScreenRecord())

                Promise.allSettled(promises).then((results) => {
                    console.log('grabacion parada por background')
                })
            }

            if (active) {
                App.removeAllListeners()

                var event = new CustomEvent('watux-background-recovered', {
                    detail: {
                        platform: this.platform,
                        active: active,
                    },
                })

                window.dispatchEvent(event)
            }
        },

        // Capacitor recording methods
        startRecording: async function (devices: any, initialized: any) {
            const promises = []

            if (devices.screen) {
                promises.push(await this.startScreenRecord())
            }

            if (devices.camera) {
                if (initialized) {
                    promises.push(await this.startVideoRecord())
                } else {
                    promises.push(await this.initializeVideoRecorder())
                }
            }
            let vm = this
            Promise.allSettled(promises)
                .then((results) => {
                    console.log('Grabacion iniciada')
                    vm.bugsnagInfo({detail:'Recording STARTED before promise'})
                    App.addListener('appStateChange', ({ isActive }) => {
                        this.registerCapacitorAppListeners(isActive)
                    })
                })
                .catch((error) => {
                    vm.bugsnagInfo({detail:'Recording FAILED before promise ' + error})
                    console.error(error)
                })
        },

        stopRecording: async function (devices: any) {
            const vm = this
            const promises = []

            let isRecording = true

            try {
                const isRecordingData = await ScreenRecorder.isRecording()
                isRecording = isRecordingData.isRecording
                vm.bugsnagInfo({detail:'Finish record, variable isRecording state = ' + isRecording})
            } catch (error) {
                vm.bugsnagInfo({detail:'Finish record, failed isRecording state'})
                throw error
            }
            
            console.log(isRecording)

            if(!devices.screen || !devices.camera || !isRecording){
                console.log('errorRecordingWarning')
                vm.bugsnagInfo({detail:'Error recording warning, show modal'})
                return this.errorRecordingWarning(vm)
            }

            promises.push(await this.stopScreenRecord())
            promises.push(await this.stopVideoRecord())

            Promise.allSettled(promises)
                .then((results) => {
                    vm.bugsnagInfo({detail:'Finish record, all promises success'})
                    var result
                    var values: any
                    for (let i = 0; i < results.length; i++) {
                        result = results[i]

                        if (result.status == 'fulfilled') {
                            values = result.value

                            if (
                                values.hasOwnProperty('type') &&
                                Object.keys(values).includes('type') &&
                                values.type == 'screen'
                            ) {
                                this.storeScreenRecord(values)
                                this.storeScreenAudio(values)
                            } else {
                                this.storeCameraRecord(values)
                                this.destroyVideoRecorder()
                            }
                        }
                    }
                })
                .catch((error) => {
                    vm.bugsnagInfo({detail:'Finish record, some promise FAILED ' + error})
                    console.log(error)
                })
        },

        // Recording Methods
        // Screen
        startScreenRecord: async function () {
            var config: RecorderConfig = {
                width: 1080,
                maxDuration: 0,
            }
            if(this.platform == 'android') document.addEventListener('resume', this.appResumeEvent);
            return await ScreenRecorder.start()
        },

        appResumeEvent(){
            document.removeEventListener('resume', this.appResumeEvent);
            let vm = this
            setTimeout(function(){
                ScreenRecorder.isRecording().then((data: any) =>{
                    if(!data.isRecording) vm.screenRecordWarning()
                })
            }, 1000)
        },
        permissionsWarning(){
            const execution: any = this.$refs.execution
            execution.showWatUxPermissionsModal()
        },
        screenRecordWarning(){
            const vm = this
            Swal.fire({
                title: this.$t('tester_required_screen_record'),
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: this.$t('continue_mode'),
                cancelButtonText: this.$t('tester_action_exit'),
                showCancelButton: true,
                allowOutsideClick: false
            }).then(async(result) =>{
                if(result){
                    vm.startScreenRecord()
                }
                else{
                    vm.closeExecution()
                }
            })
        },

        errorRecordingWarning(vm: any){
            Swal.fire({
                title: this.$t('tester_test_cant_send_videos'),
                text: this.$t('tester_test_retry'),
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: this.$t('tester_action_ok'),
                showCancelButton: false,
                allowOutsideClick: false
            }).then(async(result) =>{
                await vm.$refs.execution.fetchTestInfo()
                vm.closeExecution()
            })
        },

        stopScreenRecord: async function () {
            return await ScreenRecorder.stop()
        },

        blankVideoRecorderInitialize: async function () {
            let config: VideoRecorderPreviewFrame = {
                id: 'video-record',
                stackPosition: 'back',
                width: 0,
                height: 0,
                borderRadius: 0,
            }

            let timer: any
            let timedPromise = new Promise((resolve) => {
                timer = setTimeout(() => {
                    console.log('promise timeout')

                    let event = new CustomEvent(
                        'watux-video-recorder-initialized',
                        {
                            detail: {
                                platform: this.platform,
                                intialized: false,
                                response: [],
                            },
                        }
                    )

                    window.dispatchEvent(event)
                }, 5000)
            })
            let intializePromise = await VideoRecorder.initialize({
                camera: VideoRecorderCamera.FRONT,
                previewFrames: [config],
            }).then(() => {
                clearTimeout(timer)

                let event = new CustomEvent(
                    'watux-video-recorder-initialized',
                    {
                        detail: {
                            platform: this.platform,
                            intialized: true,
                            response: [],
                        },
                    }
                )

                window.dispatchEvent(event)
            })

            try {
                return Promise.race([timedPromise, intializePromise]).finally(
                    () => {
                        clearTimeout(timer)
                    }
                )
            } catch (errors) {
                clearTimeout(timer)

                let event = new CustomEvent(
                    'watux-video-recorder-initialized',
                    {
                        detail: {
                            platform: this.platform,
                            intialized: false,
                            response: errors,
                        },
                    }
                )

                window.dispatchEvent(event)
            }
        },

        // Camera
        initializeVideoRecorder: async function () {
            const config: VideoRecorderPreviewFrame = {
                id: 'video-record',
                stackPosition: 'back',
                width: 0,
                height: 0,
                borderRadius: 0,
            }

            await VideoRecorder.initialize({
                camera: VideoRecorderCamera.FRONT,
                previewFrames: [config],
            })
                .then(() => {
                    return this.startVideoRecord()
                })
                .catch((error: any) => {
                    console.error(error)
                })
        },

        startVideoRecord: async function () {
            return await VideoRecorder.startRecording()
        },

        stopVideoRecord: async function () {
            return await VideoRecorder.stopRecording()
        },

        destroyVideoRecorder: async function () {
            await VideoRecorder.destroy()
        },

        // Upload records
        storeScreenRecord: async function (values: any) {
            let blob = await fetch(values.videoUrlPath).then((result) =>
                result.blob()
            )

            this.bugsnagInfo({detail:'Store Screen Record file created'})

            var data = {
                testId: 'capacitor',
                file: new File([blob], 'screen.mp4', {
                    type: 'video/mp4',
                    lastModified: Date.now(),
                }),
                onUploadProgress: (progressEvent: any) => {
                    let event = new CustomEvent('watux-progress-event', {
                        detail: {
                            source: 'screen',
                            progressEvent: progressEvent,
                        },
                    })

                    window.dispatchEvent(event)
                },
            }

            watStore
                .storeTestRecordings(data)
                .then((results: any) => {
                    this.bugsnagInfo({detail:'Store Screen Record UPLOADED'})
                    var event = new CustomEvent('watux-store-record-result', {
                        detail: {
                            source: 'screen',
                            path: results.path,
                        },
                    })

                    window.dispatchEvent(event)
                })
                .catch((error: any) => {
                    console.log(error)
                    this.bugsnagInfo({detail:'Store Screen Record FAILED'})
                    var event = new CustomEvent('watux-store-record-result', {
                        detail: {
                            source: 'screen',
                            error: error,
                        },
                    })

                    window.dispatchEvent(event)
                })
        },

        storeScreenAudio: async function (values: any) {
            if (!values.hasOwnProperty('audioUrlPath')) {
                return
            }

            let blob = await fetch(values.audioUrlPath).then((result) =>
                result.blob()
            )

            this.bugsnagInfo({detail:'Store Screen Audio file created'})

            var data = {
                testId: 'capacitor',
                file: new File([blob], 'audio.aac', {
                    type: 'audio/aac',
                    lastModified: Date.now(),
                }),
                onUploadProgress: (progressEvent: any) => {
                    let event = new CustomEvent('watux-progress-event', {
                        detail: {
                            source: 'audio',
                            progressEvent: progressEvent,
                        },
                    })

                    window.dispatchEvent(event)
                },
            }

            watStore
                .storeTestAudio(data)
                .then((results: any) => {
                    this.bugsnagInfo({detail:'Store Screen Record UPLOADED'})
                    var event = new CustomEvent('watux-store-record-result', {
                        detail: {
                            source: 'audio',
                            path: results.path,
                        },
                    })

                    window.dispatchEvent(event)
                })
                .catch((error: any) => {
                    console.log(error)
                    this.bugsnagInfo({detail:'Store Screen Record FAILED'})
                    var event = new CustomEvent('watux-store-record-result', {
                        detail: {
                            source: 'audio',
                            error: error,
                        },
                    })

                    window.dispatchEvent(event)
                })
        },

        storeCameraRecord: async function (values: any) {
            let blob = await fetch(values.videoUrl).then((result) =>
                result.blob()
            )
            this.bugsnagInfo({detail:'Store Camera Record file created'})


            var data = {
                testId: 'test',
                file: new File([blob], 'camera.mp4', {
                    type: 'video/mp4',
                    lastModified: Date.now(),
                }),
                onUploadProgress: (progressEvent: any) => {
                    let event = new CustomEvent('watux-progress-event', {
                        detail: {
                            source: 'webcam',
                            progressEvent: progressEvent,
                        },
                    })

                    window.dispatchEvent(event)
                },
            }

            watStore
                .storeTestRecordings(data)
                .then((results: any) => {
                    this.bugsnagInfo({detail:'Store Camera Record UPLOADED'})
                    var event = new CustomEvent('watux-store-record-result', {
                        detail: {
                            source: 'webcam',
                            path: results.path,
                        },
                    })

                    window.dispatchEvent(event)
                })
                .catch((error: any) => {
                    this.bugsnagInfo({detail:'Store Camera Record FAILED'})
                    var event = new CustomEvent('watux-store-record-result', {
                        detail: {
                            source: 'webcam',
                            error: error,
                        },
                    })

                    window.dispatchEvent(event)
                })
        },
    },
    setup(props: any) {
        const route: any = useRoute()
        const testsStore: any = inject('testsStore')
        const notifStore: any = inject('notificationsStore')
        const authStore: any = inject('authState')
        const url: any = ref('')
        const iframeUrl: any = `${process.env.VUE_APP_EXECUTION_IFRAME_URL}/test/${props.id}/${authStore.state.authToken}?stage=questions&isAppWebView=true`
        const iframeTest: any = ref(false)
        const webTest: any = ref(false)

        let browser: any
        function loadStartCallBack(event: any) {
            if (
                event.url ==
                `${process.env.VUE_APP_EXECUTION_IFRAME_URL}/goBackToApp.html`
            ) {
                browser.close()
                history.back()
            }
        }

        const openCapacitorSite = async () => {
            iframeTest.value = true
            browser = InAppBrowser.create(
                iframeUrl,
                '_blank',
                'suppressesIncrementalRendering=yes,hidden=no,location=no,hidenavigationbuttons=yes,fullscreen=yes,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,toolbar=no,toolbarcolor=#00ff00'
            )
            browser.on('loadstart').subscribe((e: any) => {
                loadStartCallBack(e)
            })
        }
        const openWebSite = async () =>{
            webTest.value = true
            window.open(iframeUrl)
        }

        const onBriefViewed = (
            showInAppWebview: Boolean,
            isEmbeddedTestExternal: Boolean
        ) => {
            if(!Capacitor.isNativePlatform()) return
            if (showInAppWebview){
                return openCapacitorSite()
            }
            if (isEmbeddedTestExternal) {
                return openWebSite()
            }
        }

        onBeforeMount(async () => {
            await notifStore.markTestNotificationAsSeen(route.params.id)
        })

        onBeforeRouteLeave(() => {
            testsStore.getTests(0)
        })

        const router: any = useRouter()
        const goTo = (url: any) => {
            router.push({ name: url, replace: true })
        }

        return {
            url,
            goTo,
            iframeUrl,
            iframeTest,
            webTest,
            onBriefViewed,
        }
    },
})
